import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';
import GLightbox from "glightbox";

document.addEventListener("DOMContentLoaded", function (event) {

    // INIT MENUBUTTON
    const menu_button = document.querySelector('[data-behaviour="toggle-menu"]');
    menu_button.addEventListener('click', () => {
        document.body.classList.toggle('body--show');
    })

    // INIT LIGHTBOX
    const lightbox = GLightbox({
        selector: '[data-gallery="gallery"]',
        touchNavigation: true,
        loop: true,
    });

    // INIT SWIPER
    const sliders = document.querySelectorAll('.swiper');
    sliders.forEach(function (slider) {
        const autoSwiper = slider.classList.contains('swiper--auto');
        const verticalSwiper = slider.classList.contains('swiper--horizontal');
        const showmultiple = slider.classList.contains('swiper--multiple');
        console.log(autoSwiper, verticalSwiper, showmultiple);
        const swiper = new Swiper(slider, {
            // configure Swiper to use modules
            modules: [Pagination, Navigation, Autoplay, EffectFade],
            effect: 'slide',
            fadeEffect: {
                crossFade: true
            },
            direction: verticalSwiper ? 'horizontal' : 'vertical',
            loop: true,

            autoplay: autoSwiper ? {
                delay: 5000,
            } : false,

            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

            slidesPerView: showmultiple ? 2 : 1,
            spaceBetween: showmultiple ? 0 : 10,
            centeredSlides: showmultiple,

            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
        });
    });

    // FIXED HEADER
    window.addEventListener('scroll', () => {
        if (document.documentElement.scrollTop > 30 || document.body.scrollTop > 30){
            document.body.classList.add('menu--fixed');
        } else {
            document.body.classList.remove('menu--fixed');
        }
    });

    // EVENT SEARCH FILTER
    const categoryFilters = document.querySelectorAll('[data-behaviour="eventfilter--filtercategory"]');
    const monthFilter = document.querySelector('[data-behaviour="eventfilter--filtermonth"]');
    const queryFilter = document.querySelector('[data-behaviour="eventfilter--filterquery"]');
    const eventList = document.querySelector('[data-behaviour="eventfilter--list"]');
    const resetFilter = document.querySelector('[data-behaviour="eventfilter--reset"]');

    if(categoryFilters && monthFilter && queryFilter && eventList && resetFilter) {
        const items = eventList.querySelectorAll('[data-behaviour="eventfilter--item"]');
        const months = eventList.querySelectorAll('[data-behaviour="eventlist--month"]');

        let queryString = "";
        let oldQueryString = "";

        if(months && items && items.length > 0) {
            categoryFilters.forEach(filter => {
                filter.addEventListener('click', () => {
                    categoryFilters.forEach(filter => {
                        filter.classList.add('inactive');
                    });
                    filter.classList.remove('inactive');
                    filterEvents(months, items, categoryFilters, monthFilter, queryFilter);
                    checkforActiveFilters(categoryFilters, monthFilter, queryFilter, resetFilter);
                });
            });
            monthFilter.addEventListener('change', () => {
                filterEvents(months, items, categoryFilters, monthFilter, queryFilter);
                checkforActiveFilters(categoryFilters, monthFilter, queryFilter, resetFilter);
            });
            queryFilter.addEventListener('keyup', () => {
                queryString = queryFilter.value;

                //Delay the search
                setTimeout(function() {
                    if(queryString === queryFilter.value && queryString !== oldQueryString) {
                        filterEvents(months, items, categoryFilters, monthFilter, queryFilter);
                        checkforActiveFilters(categoryFilters, monthFilter, queryFilter, resetFilter);
                        oldQueryString = queryString;
                    }
                }, 200);
            });
        }

        resetFilter.addEventListener('click', () => {
            categoryFilters.forEach(filter => {
                filter.classList.remove('inactive');
            });
            monthFilter.value = 'all';
            queryFilter.value = '';
            checkforActiveFilters(categoryFilters, monthFilter, queryFilter, resetFilter);
            filterEvents(months, items, categoryFilters, monthFilter, queryFilter);
        });
    }

    function checkforActiveFilters(categoryFilters, monthFilter, queryFilter, resetFilter) {
        let anyFilterActive = false;
        categoryFilters.forEach(filter => {
            if(filter.classList.contains('inactive')) {
                anyFilterActive = true;
            }
        });
        if(monthFilter.value != 'all') {
            anyFilterActive = true;
        }
        if(queryFilter.value != '') {
            anyFilterActive = true;
        }
        if(anyFilterActive) {
            resetFilter.classList.remove('inactive');
        } else {
            resetFilter.classList.add('inactive');
        }
    }

    function filterEvents(months, items, categoryFilters, monthFilter, queryFilter){
        console.log('filtering Events...');

        months.forEach(month => {
            month.classList.remove('eventfilter--month--hidden');
        });

        items.forEach(item => {
            item.classList.remove('eventfilter--item--hidden');

            const category = item.getAttribute('data-category');
            const month = item.getAttribute('data-month');
            const query = item.getAttribute('data-title').toLowerCase();
            categoryFilters.forEach(filter => {
                if(filter.classList.contains('inactive')) {
                    console.log('inactive: ' + filter.getAttribute('data-category'));
                    if(filter.getAttribute('data-category') === category) {
                        item.classList.add('eventfilter--item--hidden');
                    }
                }
            });
            if(monthFilter.value != month && monthFilter.value != 'all') {
                item.classList.add('eventfilter--item--hidden');
            }
            if(queryFilter.value != '' && !query.includes(queryFilter.value.toLowerCase())) {
                item.classList.add('eventfilter--item--hidden');
            }
        });

        months.forEach(month => {
            month.classList.add('eventfilter--month--hidden');
            const $nextSibling = month.nextElementSibling;
            const $nextSiblingChildren = $nextSibling.querySelectorAll('[data-behaviour="eventfilter--item"]');
            let $ishidden = true;
            $nextSiblingChildren.forEach(child => {
                if(!child.classList.contains('eventfilter--item--hidden')) {
                    $ishidden = false;
                }
            });

            if(!$ishidden) {
                month.classList.remove('eventfilter--month--hidden');
            }
        });
    }

    const upbutton = document.querySelector('[data-behaviour="upbutton"]');
    if(upbutton) {
        if(document.documentElement.scrollHeight <= window.innerHeight * 1.5) {
            upbutton.style.display = 'none';
        }
        upbutton.addEventListener('click', () => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
        });
    }

    // FIXED HEADER
    window.addEventListener('scroll', () => {
        if (document.documentElement.scrollTop > 5 || document.body.scrollTop > 5){
            document.body.classList.add('menu--fixed');
        } else {
            document.body.classList.remove('menu--fixed');
        }
    });
});
